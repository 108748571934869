import React from 'react';
import './PrivacyPolicy.css'; // Import your CSS file for styling

function PrivacyPolicyEN_US() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
      WiseShare is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our platform.
      </p>

      <h2>2. Information We Collect</h2>
      <p><strong>Personal Information:</strong> We collect personal information that you voluntarily provide to us when you register on the platform, such as your name, email address, phone number, and payment information.</p>
      <p><strong>Usage Data:</strong> We automatically collect certain information when you access the platform, such as your IP address, browser type, and activity on the platform.</p>
      <p><strong>Cookies:</strong> We use cookies and similar technologies to track activity on our platform and to store certain information.</p>

      <h2>3. How We Use Your Information</h2>
      <p><strong>Service Provision:</strong> We use your personal information to provide you with services, process transactions, and manage your account.</p>
      <p><strong>Communication:</strong> We use your information to contact you regarding your account, respond to your inquiries, and send you promotional materials (with your consent).</p>
      <p><strong>Platform Improvement:</strong> We use usage data to monitor and improve our platform’s performance and functionality.</p>
      <p><strong>Legal Compliance:</strong> We may use your information to comply with legal obligations, such as responding to legal requests and preventing fraud.</p>

      <h2>4. Information Sharing and Disclosure</h2>
      <p><strong>With Other Users:</strong> Your profile information and listings may be visible to other users of the platform.</p>
      <p><strong>With Third-Party Service Providers:</strong> We share information with third-party service providers who perform services on our behalf, such as payment processing and identity verification.</p>
      <p><strong>For Legal Purposes:</strong> We may disclose your information if required by law or if we believe that such disclosure is necessary to protect our rights, investigate fraud, or ensure user safety.</p>
      <p><strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred as part of that transaction.</p>

      <h2>5. Data Security</h2>
      <p>
        We implement reasonable security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        We retain your personal information for as long as your account is active or as needed to provide you with services. We may also retain your information to comply with legal obligations, resolve disputes, and enforce our agreements.
      </p>

      <h2>7. Your Rights</h2>
      <p><strong>Access and Correction:</strong> You have the right to access and update your personal information. You can do this through your account settings.</p>
      <p><strong>Deletion:</strong> You can request that we delete your personal information. However, this may limit your ability to use certain features of the platform.</p>
      <p><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications from us at any time.</p>

      <h2>8. Third-Party Links</h2>
      <p>
        Our platform may contain links to third-party websites or services that are not operated by us. We are not responsible for the content, privacy policies, or practices of any third-party websites or services.
      </p>

      <h2>9. International Data Transfers</h2>
      <p>
        Your information may be transferred to and maintained on computers located outside of your country or other governmental jurisdiction where the data protection laws may differ. By using the platform, you consent to the transfer of your information to such locations.
      </p>

      <h2>10. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform. Your continued use of the platform after any changes signifies your acceptance of the new Privacy Policy.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at [Contact Information].
      </p>
    </div>
  );
}

export default PrivacyPolicyEN_US;
