import React from 'react';
import { useTranslation } from 'react-i18next';
import TermsAndConditionsEnUs from './TermsAndConditionsEnUs';
import TermsAndConditionsDeDe from './TermsAndConditionsDeDe';
import TermsAndConditionsItIt from './TermsAndConditionsItIt';

function TermsAndConditionsLoader() {
  const { i18n } = useTranslation();
  const language = i18n.language; // e.g., 'en', 'de', 'it'

  // Map language codes if necessary
  const languageMap = {
    'en': 'en-US',
    'de': 'de-DE',
    'it': 'it-IT',
  };

  const mappedLanguage = languageMap[language] || 'en-US';

  switch (mappedLanguage) {
    case 'de-DE':
      return <TermsAndConditionsDeDe />;
    case 'it-IT':
      return <TermsAndConditionsItIt />;
    default:
      return <TermsAndConditionsEnUs />;
  }
}

export default TermsAndConditionsLoader;
