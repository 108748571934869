import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import './Profile.css';

function Profile() {
  const { t } = useTranslation(); // Destructure t from useTranslation
  const [user, setUser] = useState(null);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProfile() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setUser(response.data.user);
        setItems(response.data.items);
      } catch (error) {
        setError(t('Failed to load profile'));
        console.error('Error fetching profile', error);
        navigate('/login'); // Redirect to login if there's an error (unauthorized)
      }
    }
    fetchProfile();
  }, [navigate, t]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleDelete = async (itemId) => {
    if (window.confirm(t('Are you sure you want to delete this item?'))) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/items/${itemId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setItems(items.filter(item => item.id !== itemId));
      } catch (error) {
        setError(t('Failed to delete item'));
        console.error('Error deleting item', error);
      }
    }
  };

  return (
    <div className="profile-container">
      {error && <p className="error-message">{error}</p>}
      {user && (
        <>
          <h1>{user.username}'s {t('Profile')}</h1>
          <Link to="/profile/chats" className="profile-chats-link">{t('messages')}</Link>
          <Link to="/add-item" className="add-item-link">{t('addItem')}</Link> {/* Add Item button */}
          <h2>{t('Items Posted')}:</h2>
          <ul className="item-list">
            {items.map((item) => (
              <li key={item.id} className="item">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p>{t('Price')}: {item.price} €/day</p>
                {item.imageurl && <img src={item.imageurl} alt={item.title} className="item-image" />}
                <p>{t('Category')}: {item.category}</p>
                <div className="button-container">
                  {/* Add Edit Button */}
                  <button onClick={() => navigate(`/edit-item/${item.id}`)} className="edit-button">{t('Edit')}</button>
                  {/* Add Delete Button */}
                  <button onClick={() => handleDelete(item.id)} className="delete-button">{t('Delete')}</button>
                </div>
              </li>
            ))}
          </ul>
          {/* Move Logout Link here */}
          <button onClick={handleLogout} className="logout-link">{t('Logout')}</button>
        </>
      )}
    </div>
  );
}

export default Profile;
