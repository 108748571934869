import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ConversationDetail.css';

function ConversationDetail() {
  const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [yourUserId, setYourUserId] = useState(null);
  const [receiverId, setReceiverId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchUserId() {
      const token = localStorage.getItem('token');
      if (!token) {
        setError(t('User not authenticated'));
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setYourUserId(response.data.user.id);
      } catch (error) {
        setError(t('Failed to fetch user profile'));
        console.error('Error fetching user profile:', error);
      }
    }

    fetchUserId();
  }, [t]);

  useEffect(() => {
    if (yourUserId === null) return;

    async function fetchMessages() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/${conversationId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        // Ensure messages are sorted by timestamp in ascending order
        const sortedMessages = response.data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        setMessages(sortedMessages);

        const messageIds = sortedMessages
          .filter(msg => msg.sender_id !== yourUserId)
          .map(msg => msg.id);

        if (messageIds.length > 0) {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/messages/read`, {
            messageIds
          }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          });

          // Notify the Navbar to update unread message count
          localStorage.setItem('unreadMessages', res.data.count);
          window.dispatchEvent(new Event('unreadMessagesUpdated'));
        }

        if (receiverId === null && sortedMessages.length > 0) {
          const firstMessage = sortedMessages[0];
          setReceiverId(firstMessage.sender_id === yourUserId ? sortedMessages.find(msg => msg.sender_id !== yourUserId)?.sender_id : firstMessage.sender_id);
        }
      } catch (error) {
        setError(t('Failed to load messages'));
        console.error('Error fetching messages:', error);
      }
    }

    fetchMessages();
  }, [conversationId, yourUserId, t, receiverId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (receiverId === null) {
      setError(t('Receiver not found'));
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/messages`, {
        conversationId,
        message,
        receiverId
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      const newMessage = { id: response.data.id, message, sender_id: yourUserId, timestamp: new Date().toISOString() };

      // Append the new message to the sorted list
      const updatedMessages = [...messages, newMessage].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      setMessages(updatedMessages);
      setMessage('');
    } catch (error) {
      setError(t('Failed to send message'));
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="conversation-detail-container">
      <Link to="/profile/chats" className="back-link">{t('Back to Chats')}</Link> {/* Move link to top */}
      <h1>{t('Conversation Details')}</h1>
      {error && <p className="error-message">{error}</p>}
      
      <div className="messages">
        {messages.map((msg) => (
          <div key={msg.id} className={`message ${msg.sender_id === yourUserId ? 'sent' : 'received'}`}>
            <p>{msg.message}</p>
          </div>
        ))}
      </div>
      <div className="message-form">
        <form onSubmit={handleSendMessage}>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t('Write your message here')}
            required
          />
          <button type="submit">{t('Send')}</button>
        </form>
      </div>
    </div>
  );
}

export default ConversationDetail;
