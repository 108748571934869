import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ItemDetail.css';

function ItemDetail() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchItemDetail() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${id}`);
        setItem(response.data);
      } catch (error) {
        setError('Failed to load item details');
        console.error('Error fetching item details:', error);
      }
    }
    fetchItemDetail();
  }, [id]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
  
    console.log('Item:', item); // Check if item is set correctly
    console.log('Item User ID:', item?.userId); // Check if userId is set correctly
  
    if (!item || !item.userId) {
      setError('Item or item user not found');
      return;
    }
  
    const token = localStorage.getItem('token');
    if (!token) {
      setError('User not authenticated');
      return;
    }

    try {
      // Create a new conversation or get an existing one with item_id
      const conversationResponse = await axios.post(`${process.env.REACT_APP_API_URL}/conversations`, {
        recipientId: item.userId,
        itemId: item.id, // Pass item_id to associate with conversation
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const conversationId = conversationResponse.data.id;

      // Send the message
      await axios.post(`${process.env.REACT_APP_API_URL}/messages`, {
        conversationId,
        message,
        receiverId: item.userId, // Pass receiverId to the server
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setMessage('');
      alert('Message sent!');
    } catch (error) {
      setError('Failed to send message');
      console.error('Error sending message:', error);
    }
  };

  if (error) return <p className="error-message">{error}</p>;
  if (!item) return <div>Loading...</div>;

  return (
    <div className="item-detail-container">
      <h1>{item.title}</h1>
      {item.imageurl && <img src={item.imageurl} alt={item.title} className="item-image" />}
      <p>{item.description}</p>
      <p><strong>Price:</strong> {item.price} €/day</p>
      <p><strong>Category:</strong> {item.category}</p>
      <p><strong>Posted by:</strong> {item.username}</p> {/* Display the username here */}

      <div className="message-form">
        <h2>Send a Message</h2>
        <form onSubmit={handleSendMessage}>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write your message here"
            required
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}

export default ItemDetail;
