import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ProfileChats.css';

function ProfileChats() {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUserId() {
      const token = localStorage.getItem('token');
      if (!token) {
        setError(t('User not authenticated'));
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserId(response.data.user.id);
      } catch (error) {
        setError(t('Failed to fetch user profile'));
        console.error('Error fetching user profile:', error);
        setLoading(false);
      }
    }

    fetchUserId();
  }, [t]);

  useEffect(() => {
    if (userId === null) return;

    async function fetchConversations() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError(t('User not authenticated'));
          setLoading(false);
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/conversations`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const conversationsData = response.data;

        setConversations(conversationsData);
      } catch (error) {
        setError(t('Failed to load conversations'));
        console.error('Error fetching conversations:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchConversations();
  }, [userId, t]);

  return (
    <div className="profile-chats-container">
      <Link to="/profile" className="back-to-profile-link">
        {t('Back to Profile')}
      </Link>
      <h1>{t('Your Chats')}</h1>
      {error && <p className="error-message">{error}</p>}
      {loading ? (
        <p>{t('Loading...')}</p>
      ) : conversations.length === 0 ? (
        <p>{t('No conversations found')}</p>
      ) : (
        <ul>
          {conversations.map((conversation) => (
            <li key={conversation.id}>
              <Link to={`/conversations/${conversation.id}`} className="conversation-link">
                <div className="item-info">
                  <img
                    src={conversation.itemImageUrl}
                    alt={conversation.itemTitle}
                    className="item-image"
                  />
                  <div className="item-details">
                    <div className="item-title">{conversation.itemTitle}</div>
                    <div className="user-name">{conversation.userName}</div>
                  </div>
                </div>
                <span className="timestamp">
                  {conversation.lastMessageTimestamp
                    ? new Date(conversation.lastMessageTimestamp).toLocaleString()
                    : t('No messages yet')}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ProfileChats;
