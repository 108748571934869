import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css';

function Navbar() {
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const handleUnreadMessagesUpdate = () => {
      const count = parseInt(localStorage.getItem('unreadMessages'), 10) || 0;
      setUnreadMessages(count);
    };

    const fetchUnreadMessages = async () => {
      if (token) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/unread-messages`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setUnreadMessages(data.count || 0);
          localStorage.setItem('unreadMessages', data.count || 0);
        } catch (error) {
          console.error('Error fetching unread messages:', error);
        }
      }
    };

    fetchUnreadMessages();

    window.addEventListener('unreadMessagesUpdated', handleUnreadMessagesUpdate);

    return () => {
      window.removeEventListener('unreadMessagesUpdated', handleUnreadMessagesUpdate);
    };
  }, [token]);

  const handleProfileClick = () => {
    closeMenu(); // Close the menu when navigating to the profile
    if (token) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle mobile menu visibility
  };

  const closeMenu = () => {
    setMenuOpen(false); // Close the mobile menu
  };

  const currentLanguage = i18n.language;
  const languageInitials = currentLanguage === 'en' ? 'EN' : 
                           currentLanguage === 'it' ? 'IT' : 'DE';

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMenu}>{t('appName')}</Link>
        <button className="mobile-menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <ul className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
          <li><Link to="/" className="navbar-link" onClick={closeMenu}>{t('home')}</Link></li>
          <li><Link to="/items" className="navbar-link" onClick={closeMenu}>{t('items')}</Link></li>
          <li><Link to="/add-item" className="navbar-link" onClick={closeMenu}>{t('addItem')}</Link></li>
          {token && (
            <li className="messages-link">
              <Link to="/profile/chats" className="navbar-link" onClick={closeMenu}>{t('messages')}</Link>
              {unreadMessages > 0 && (
                <span className="message-badge">{unreadMessages}</span>
              )}
            </li>
          )}
          <li>
            <button onClick={handleProfileClick} className="navbar-profile-button">
              <img src="/profileIcon.svg" alt={t('profile')} className="profile-icon" />
            </button>
          </li>
          <li className="language-switcher">
            <button onClick={toggleDropdown} className="language-icon">
              {languageInitials}
            </button>
            {dropdownOpen && (
              <div className="language-dropdown">
                <button onClick={() => handleLanguageChange('en')} className="dropdown-item">EN</button>
                <button onClick={() => handleLanguageChange('de')} className="dropdown-item">DE</button>
                <button onClick={() => handleLanguageChange('it')} className="dropdown-item">IT</button>
              </div>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;


