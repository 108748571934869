import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <p>&copy; 2024 {t('appName')}. {t('allRightsReserved')}</p>
      <div className="footer-links">
        <Link to="/terms-and-conditions">{t('termsAndConditions')}</Link>
        <Link to="/privacy-policy">{t('privacyPolicy')}</Link>
      </div>
    </footer>
  );
}

export default Footer;
