import React from 'react';
import './PrivacyPolicy.css'; // Import your CSS file for styling

function PrivacyPolicyIT_IT() {
  return (
    <div className="privacy-policy">
      <h1>Informativa sulla Privacy</h1>
      
      <h2>1. Introduzione</h2>
      <p>WiseShare si impegna a proteggere la tua privacy. Questa Informativa sulla Privacy descrive come raccogliamo, utilizziamo, divulghiamo e proteggiamo le tue informazioni quando visiti la nostra piattaforma.</p>
      
      <h2>2. Quali Dati Raccogliamo</h2>
      <h3>Dati Personali:</h3>
      <p>Raccogliamo dati personali che ci fornisci volontariamente quando ti registri sulla piattaforma, come il tuo nome, indirizzo email, numero di telefono e informazioni di pagamento.</p>
      
      <h3>Dati di Utilizzo:</h3>
      <p>Raccogliamo automaticamente determinate informazioni quando accedi alla piattaforma, come il tuo indirizzo IP, il tipo di browser utilizzato e le tue attività sulla piattaforma.</p>
      
      <h3>Cookie:</h3>
      <p>Utilizziamo cookie e tecnologie simili per monitorare l'attività sulla nostra piattaforma e memorizzare alcune informazioni.</p>
      
      <h2>3. Come Utilizziamo le Tue Informazioni</h2>
      <h3>Fornitura dei Servizi:</h3>
      <p>Utilizziamo i tuoi dati personali per fornirti servizi, elaborare transazioni e gestire il tuo account.</p>
      
      <h3>Comunicazione:</h3>
      <p>Utilizziamo le tue informazioni per contattarti riguardo al tuo account, rispondere alle tue richieste e inviarti materiali promozionali (con il tuo consenso).</p>
      
      <h3>Miglioramento della Piattaforma:</h3>
      <p>Utilizziamo i dati di utilizzo per monitorare e migliorare le prestazioni e la funzionalità della nostra piattaforma.</p>
      
      <h3>Conformità Legale:</h3>
      <p>Possiamo utilizzare le tue informazioni per rispettare obblighi legali, come rispondere a richieste legali e prevenire frodi.</p>
      
      <h2>4. Condivisione e Divulgazione delle Informazioni</h2>
      <h3>Con Altri Utenti:</h3>
      <p>Le informazioni del tuo profilo e le inserzioni possono essere visibili ad altri utenti della piattaforma.</p>
      
      <h3>Con Fornitori di Servizi Terzi:</h3>
      <p>Condividiamo informazioni con fornitori di servizi terzi che eseguono servizi per nostro conto, come l'elaborazione dei pagamenti e la verifica dell'identità.</p>
      
      <h3>Per Scopi Legali:</h3>
      <p>Possiamo divulgare le tue informazioni se richiesto dalla legge o se crediamo che tale divulgazione sia necessaria per proteggere i nostri diritti, investigare frodi o garantire la sicurezza degli utenti.</p>
      
      <h3>Trasferimenti Aziendali:</h3>
      <p>In caso di fusione, acquisizione o vendita di beni, le tue informazioni potrebbero essere trasferite come parte di tale transazione.</p>
      
      <h2>5. Sicurezza dei Dati</h2>
      <p>Implementiamo misure di sicurezza ragionevoli per proteggere i tuoi dati personali. Tuttavia, nessun metodo di trasmissione su Internet o archiviazione elettronica è completamente sicuro, e non possiamo garantire una sicurezza assoluta.</p>
      
      <h2>6. Conservazione dei Dati</h2>
      <p>Conserviamo i tuoi dati personali per tutto il tempo in cui il tuo account è attivo o per il tempo necessario a fornirti servizi. Possiamo anche conservare le tue informazioni per rispettare obblighi legali, risolvere controversie e far valere i nostri accordi.</p>
      
      <h2>7. I Tuoi Diritti</h2>
      <h3>Accesso e Correzione:</h3>
      <p>Hai il diritto di accedere e aggiornare i tuoi dati personali. Puoi farlo attraverso le impostazioni del tuo account.</p>
      
      <h3>Cancellazione:</h3>
      <p>Puoi richiedere che cancelliamo i tuoi dati personali. Tuttavia, questo potrebbe limitare la tua capacità di utilizzare determinate funzionalità della piattaforma.</p>
      
      <h3>Opt-Out:</h3>
      <p>Puoi scegliere di non ricevere comunicazioni promozionali da parte nostra in qualsiasi momento.</p>
      
      <h2>8. Link a Terzi</h2>
      <p>La nostra piattaforma può contenere link a siti web o servizi di terzi che non sono gestiti da noi. Non siamo responsabili per i contenuti, le politiche sulla privacy o le pratiche di siti web o servizi di terzi.</p>
      
      <h2>9. Trasferimenti Internazionali di Dati</h2>
      <p>Le tue informazioni possono essere trasferite a, e mantenute su, computer situati al di fuori del tuo paese o di altre giurisdizioni governative in cui le leggi sulla protezione dei dati potrebbero differire. Utilizzando la piattaforma, acconsenti al trasferimento delle tue informazioni in tali località.</p>
      
      <h2>10. Modifiche a Questa Informativa sulla Privacy</h2>
      <p>Potremmo aggiornare la nostra Informativa sulla Privacy di tanto in tanto. Ti informeremo di eventuali modifiche pubblicando la nuova Informativa sulla Privacy sulla nostra piattaforma. L'uso continuato della piattaforma dopo qualsiasi modifica significa che accetti la nuova Informativa sulla Privacy.</p>
      
      <h2>11. Contatti</h2>
      <p>Se hai domande o preoccupazioni riguardo a questa Informativa sulla Privacy, contattaci all'indirizzo [Informazioni di Contatto].</p>
    </div>
  );
}

export default PrivacyPolicyIT_IT;
