import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './Register.css'; // Import the CSS file

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false); // State for terms agreement
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State for success modal
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password || !email) {
      setError('All fields are required');
      return;
    }
    if (!agreeToTerms) {
      setError('You must agree to the terms and conditions and privacy policy');
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        { username, password, email },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setShowSuccessModal(true); // Show success modal on successful registration
    } catch (error) {
      setError('Registration failed');
      console.error('Registration failed', error);
    }
  };

  const closeModalAndRedirect = () => {
    setShowSuccessModal(false);
    navigate('/login'); // Redirect to login page after closing modal
  };

  return (
    <div className="register-container">
      <h2 className="register-title">Register</h2>
      {error && <p className="register-error">{error}</p>}
      <form onSubmit={handleSubmit} className="register-form">
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Profile Name"
          className="register-input"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="register-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="register-input"
        />
        <div className="terms-container">
          <input
            type="checkbox"
            id="terms"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
          />
          <label htmlFor="terms">
            I agree to the{' '}
            <Link to="/terms-and-conditions" target="_blank" className="terms-link">
              Terms and Conditions
            </Link>{' '}
            and{' '}
            <Link to="/privacy-policy" target="_blank" className="terms-link">
              Privacy Policy
            </Link>.
          </label>
        </div>
        <button type="submit" className="register-button">Register</button>
      </form>
      <p className="register-link-container">Already have an account? <Link to="/login" className="register-link">Login here</Link></p>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Registration Successful!</h3>
            <p>Your account has been created successfully. You can now log in.</p>
            <button onClick={closeModalAndRedirect} className="modal-button">OK</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
