import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Autocomplete } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import './EditItem.css';

function EditItem() {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [image, setImage] = useState(null);
  const [originalImage, setOriginalImage] = useState('');
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const imageInputRef = useRef(null); // Ref for the file input

  useEffect(() => {
    async function fetchItem() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${itemId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        const itemData = response.data;
        setItem(itemData);
        setTitle(itemData.title);
        setDescription(itemData.description);
        setPrice(itemData.price);
        setCategoryId(itemData.categoryid);
        setOriginalImage(itemData.imageurl);
        setAddress(itemData.location);
        setLatitude(itemData.latitude);
        setLongitude(itemData.longitude);
      } catch (error) {
        setError(t('Failed to fetch item details'));
        console.error('Error fetching item details:', error);
      }
    }

    async function fetchCategories() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setCategories(response.data);
      } catch (error) {
        setError(t('Failed to fetch categories'));
        console.error('Error fetching categories:', error);
      }
    }

    fetchItem();
    fetchCategories();
  }, [itemId, t]);

  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    if (place && place.formatted_address) {
      setAddress(place.formatted_address);
      if (place.geometry) {
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('categoryId', categoryId);
    formData.append('location', address);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);

    if (image) {
      formData.append('image', image);
    } else {
      formData.append('imageUrl', originalImage);
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/items/${itemId}`, formData, {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate('/profile');
    } catch (error) {
      setError(t('Failed to update item'));
      console.error('Error updating item:', error);
    }
  };

  return (
    <div className="edit-item-container">
      <h1>{t('editItem')}</h1>
      {error && <p className="error-message">{error}</p>}
      {item && (
        <form onSubmit={handleSubmit}>
          <label htmlFor="title">{t('title')}</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          
          <label htmlFor="description">{t('description')}</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          
          <label htmlFor="price">{t('price')}</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />

          <label htmlFor="image-upload" className="image-upload-label">
            {t('chooseImage')}
            <input
              type="file"
              id="image-upload"
              ref={imageInputRef}
              onChange={(e) => setImage(e.target.files[0])}
              style={{ display: 'none' }} // Hide the default file input
            />
          </label>
          
          <label htmlFor="category">{t('category')}</label>
          <select
            id="category"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            required
          >
            <option value="" disabled>{t('selectCategory')}</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>

          <label htmlFor="location">{t('location')}</label>
          <Autocomplete
            onLoad={(autocomplete) => setAutocomplete(autocomplete)}
            onPlaceChanged={handlePlaceSelect}
            options={{
              types: ['(regions)'],
              componentRestrictions: { country: ['de','it']}
            }}
          >
            <input
              type="text"
              id="location"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={t('enterLocation')}
              required
            />
          </Autocomplete>
          
          <button type="submit">{t('saveChanges')}</button>
        </form>
      )}
    </div>
  );
}

export default EditItem;
