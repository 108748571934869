import React from 'react';
import './TermsAndConditions.css'; // Import your CSS file for styling

function TermsAndConditionsDE_DE() {
  return (
    <div className="terms-and-conditions">
      <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>
      
      <h2>1. Einführung</h2>
      <p>
        Willkommen bei WiseShare. Diese Allgemeinen Geschäftsbedingungen ("AGB") regeln Ihre Nutzung unserer Plattform. Durch den Zugriff auf oder die Nutzung unserer Plattform erklären Sie sich mit diesen AGB einverstanden. Wenn Sie diesen AGB nicht zustimmen, dürfen Sie unsere Plattform nicht nutzen.
      </p>

      <h2>2. Definitionen</h2>
      <p>
        "Plattform" bezieht sich auf WiseShare.<br />
        "Nutzer" bezeichnet jede natürliche oder juristische Person, die ein Konto registriert oder die Plattform nutzt.<br />
        "Vermieter" bezeichnet Nutzer, die Artikel zur Miete anbieten.<br />
        "Mieter" bezeichnet Nutzer, die Artikel mieten, die auf der Plattform angeboten werden.<br />
        "Drittanbieter-Dienstleister" bezieht sich auf externe Unternehmen, die Zahlungsabwicklung, Identitätsprüfung oder andere Dienstleistungen erbringen.
      </p>

      <h2>3. Dienstleistungen der Plattform</h2>
      <p>
        Die Plattform bietet eine Möglichkeit für Vermieter und Mieter, miteinander in Kontakt zu treten, um Artikel zu vermieten. Die Plattform besitzt, verwaltet oder betreibt die angebotenen Artikel nicht und ist an keiner Transaktion oder keinem Vertrag zwischen Vermietern und Mietern beteiligt, außer der Bereitstellung der technischen Infrastruktur.
      </p>

      <h2>4. Pflichten der Nutzer</h2>
      <p>
        Genaue Informationen: Nutzer müssen genaue und vollständige Informationen bei der Erstellung von Konten und bei der Auflistung von Artikeln bereitstellen.<br />
        Einhaltung der Gesetze: Nutzer verpflichten sich, alle anwendbaren Gesetze, Vorschriften und Verordnungen im Zusammenhang mit der Nutzung der Plattform und der Vermietung von Artikeln einzuhalten.<br />
        Zustand und Sicherheit der Artikel: Vermieter sind dafür verantwortlich, dass die Artikel sicher, funktionsfähig und wie beschrieben sind. Mieter sind dafür verantwortlich, die Artikel gemäß den Anweisungen zu verwenden und in demselben Zustand zurückzugeben.
      </p>

      <h2>5. Haftungsbeschränkung der Plattform</h2>
      <p>
        Keine Garantie: Die Plattform übernimmt keine Garantie für die Qualität, Sicherheit, Rechtmäßigkeit oder Eignung der auf der Plattform angebotenen Artikel. Alle Artikel werden "wie besehen" ohne jegliche ausdrückliche oder stillschweigende Gewährleistungen angeboten.<br />
        Keine Befürwortung: Die Plattform befürwortet keine Artikel, Nutzer oder Angebote. Die Aufnahme von Artikeln auf der Plattform impliziert keine Zustimmung oder Empfehlung durch die Plattform.<br />
        Keine Haftung für Transaktionen: Die Plattform ist keine Vertragspartei einer Miettransaktion und haftet nicht für Streitigkeiten, Ansprüche, Verluste oder Schäden, die sich aus oder im Zusammenhang mit solchen Transaktionen ergeben. Alle Transaktionen erfolgen ausschließlich zwischen Vermieter und Mieter.<br />
        Keine Verantwortung für die Funktionalität der Artikel: Die Plattform ist nicht verantwortlich für die Funktionalität, Sicherheit oder Rechtmäßigkeit der über die Plattform gemieteten Artikel. Probleme mit den Artikeln müssen direkt zwischen Vermieter und Mieter geklärt werden.<br />
        Keine Haftung für Verlust oder Beschädigung: Die Plattform haftet nicht für Verlust, Diebstahl oder Beschädigung von Artikeln während der Mietdauer, noch für Schäden an persönlichem Eigentum oder Verletzungen von Personen, die durch die Nutzung gemieteter Artikel entstehen.<br />
        Keine Verantwortung für Nichtzahlung: Die Plattform ist nicht verantwortlich für die Sicherstellung der Zahlung zwischen Vermietern und Mietern. Zahlungsstreitigkeiten müssen direkt zwischen den beteiligten Parteien geklärt werden.<br />
        Keine Haftung für Drittanbieterdienste: Die Plattform kann Drittanbieter-Dienstleister für Zahlungsabwicklung, Identitätsprüfung oder andere Dienstleistungen nutzen. Die Plattform ist nicht verantwortlich für Handlungen oder Unterlassungen dieser Drittanbieter.
      </p>

      <h2>6. Freistellung</h2>
      <p>
        Die Nutzer verpflichten sich, die Plattform, ihre verbundenen Unternehmen, Führungskräfte, Direktoren, Mitarbeiter und Vertreter von allen Ansprüchen, Verbindlichkeiten, Schäden, Verlusten oder Kosten, einschließlich angemessener Anwaltsgebühren, freizustellen, zu verteidigen und schadlos zu halten, die sich aus oder in Verbindung mit (i) dem Zugang des Nutzers zur Plattform oder deren Nutzung, (ii) den über die Plattform gemieteten Artikeln, (iii) einem Verstoß gegen diese AGB oder (iv) einem Verstoß gegen geltende Gesetze oder Vorschriften ergeben.
      </p>

      <h2>7. Streitbeilegung</h2>
      <p>
        Mediation und Schiedsverfahren: Alle Streitigkeiten, die sich aus der Nutzung der Plattform ergeben oder damit in Zusammenhang stehen, werden durch Mediation oder Schiedsverfahren beigelegt, wie es von den beteiligten Parteien einvernehmlich vereinbart wird, bevor ein Gerichtsverfahren angestrebt wird.<br />
        Geltendes Recht: Diese AGB unterliegen dem Recht der Bundesrepublik Deutschland. Jegliche rechtlichen Auseinandersetzungen müssen vor den Gerichten der Bundesrepublik Deutschland ausgetragen werden.
      </p>

      <h2>8. Kündigung und Sperrung von Konten</h2>
      <p>
        Die Plattform behält sich das Recht vor, Nutzerkonten nach eigenem Ermessen und ohne vorherige Ankündigung zu sperren oder zu kündigen, insbesondere bei Verdacht auf Betrug, Missbrauch oder Verstoß gegen diese AGB. Die Plattform kann auch Angebote entfernen oder ändern, die gegen diese AGB verstoßen oder als unangemessen erachtet werden.
      </p>

      <h2>9. Änderungen der AGB</h2>
      <p>
        Die Plattform behält sich das Recht vor, diese AGB jederzeit zu ändern. Die Nutzer werden über wesentliche Änderungen informiert, und die fortgesetzte Nutzung der Plattform gilt als Zustimmung zu den geänderten AGB.
      </p>

      <h2>10. Gesamte Vereinbarung</h2>
      <p>
        Diese AGB stellen die gesamte Vereinbarung zwischen dem Nutzer und der Plattform in Bezug auf die Nutzung der Plattform dar und ersetzen alle vorherigen Vereinbarungen oder Absprachen, ob schriftlich oder mündlich, die sich auf diese Nutzung beziehen.
      </p>
    </div>
  );
}

export default TermsAndConditionsDE_DE;
