import React from 'react';
import './PrivacyPolicy.css'; // Import your CSS file for styling

function PrivacyPolicyDE_DE() {
  return (
    <div className="privacy-policy">
      <h1>Datenschutzerklärung</h1>

      <h2>1. Einführung</h2>
      <p>
        WiseShare verpflichtet sich, Ihre Privatsphäre zu schützen. Diese Datenschutzerklärung beschreibt, wie wir Ihre Daten erfassen, nutzen, offenlegen und schützen, wenn Sie unsere Plattform besuchen.
      </p>

      <h2>2. Welche Daten wir erfassen</h2>
      <p>
        <strong>Personenbezogene Daten:</strong> Wir erfassen personenbezogene Daten, die Sie uns freiwillig zur Verfügung stellen, wenn Sie sich auf der Plattform registrieren, wie Ihren Namen, Ihre E-Mail-Adresse, Telefonnummer und Zahlungsinformationen.
      </p>
      <p>
        <strong>Nutzungsdaten:</strong> Wir erfassen automatisch bestimmte Informationen, wenn Sie auf die Plattform zugreifen, wie Ihre IP-Adresse, den verwendeten Browsertyp und Ihre Aktivitäten auf der Plattform.
      </p>
      <p>
        <strong>Cookies:</strong> Wir verwenden Cookies und ähnliche Technologien, um Aktivitäten auf unserer Plattform zu verfolgen und bestimmte Informationen zu speichern.
      </p>

      <h2>3. Wie wir Ihre Daten verwenden</h2>
      <p>
        <strong>Bereitstellung von Dienstleistungen:</strong> Wir verwenden Ihre personenbezogenen Daten, um Ihnen Dienstleistungen anzubieten, Transaktionen abzuwickeln und Ihr Konto zu verwalten.
      </p>
      <p>
        <strong>Kommunikation:</strong> Wir verwenden Ihre Daten, um Sie bezüglich Ihres Kontos zu kontaktieren, auf Ihre Anfragen zu antworten und Ihnen Werbematerialien zuzusenden (mit Ihrer Einwilligung).
      </p>
      <p>
        <strong>Plattformverbesserung:</strong> Wir verwenden Nutzungsdaten, um die Leistung und Funktionalität unserer Plattform zu überwachen und zu verbessern.
      </p>
      <p>
        <strong>Gesetzliche Einhaltung:</strong> Wir können Ihre Daten verwenden, um gesetzlichen Verpflichtungen nachzukommen, wie etwa der Beantwortung rechtlicher Anfragen und der Betrugsprävention.
      </p>

      <h2>4. Informationsweitergabe und Offenlegung</h2>
      <p>
        <strong>Mit anderen Nutzern:</strong> Ihre Profilinformationen und Angebote können für andere Nutzer der Plattform sichtbar sein.
      </p>
      <p>
        <strong>Mit Drittanbieter-Dienstleistern:</strong> Wir teilen Informationen mit Drittanbieter-Dienstleistern, die in unserem Auftrag Dienstleistungen erbringen, wie z.B. Zahlungsabwicklung und Identitätsprüfung.
      </p>
      <p>
        <strong>Zu rechtlichen Zwecken:</strong> Wir können Ihre Daten offenlegen, wenn dies gesetzlich vorgeschrieben ist oder wenn wir glauben, dass eine solche Offenlegung erforderlich ist, um unsere Rechte zu schützen, Betrug zu untersuchen oder die Sicherheit der Nutzer zu gewährleisten.
      </p>
      <p>
        <strong>Geschäftsübertragungen:</strong> Im Falle einer Fusion, Übernahme oder eines Verkaufs von Vermögenswerten können Ihre Daten im Rahmen dieser Transaktion übertragen werden.
      </p>

      <h2>5. Datensicherheit</h2>
      <p>
        Wir setzen angemessene Sicherheitsmaßnahmen ein, um Ihre personenbezogenen Daten zu schützen. Allerdings ist keine Methode der Übertragung über das Internet oder der elektronischen Speicherung vollständig sicher, und wir können keine absolute Sicherheit garantieren.
      </p>

      <h2>6. Datenspeicherung</h2>
      <p>
        Wir speichern Ihre personenbezogenen Daten so lange, wie Ihr Konto aktiv ist oder wie es erforderlich ist, um Ihnen Dienstleistungen bereitzustellen. Wir können Ihre Daten auch speichern, um gesetzlichen Verpflichtungen nachzukommen, Streitigkeiten zu lösen und unsere Vereinbarungen durchzusetzen.
      </p>

      <h2>7. Ihre Rechte</h2>
      <p>
        <strong>Zugriff und Berichtigung:</strong> Sie haben das Recht, auf Ihre personenbezogenen Daten zuzugreifen und diese zu aktualisieren. Dies können Sie über die Einstellungen Ihres Kontos tun.
      </p>
      <p>
        <strong>Löschung:</strong> Sie können verlangen, dass wir Ihre personenbezogenen Daten löschen. Dies kann jedoch Ihre Fähigkeit einschränken, bestimmte Funktionen der Plattform zu nutzen.
      </p>
      <p>
        <strong>Widerspruch:</strong> Sie können sich jederzeit gegen den Erhalt von Werbemitteilungen von uns entscheiden.
      </p>

      <h2>8. Links zu Drittanbietern</h2>
      <p>
        Unsere Plattform kann Links zu Websites oder Diensten von Drittanbietern enthalten, die nicht von uns betrieben werden. Wir sind nicht verantwortlich für die Inhalte, Datenschutzrichtlinien oder Praktiken von Websites oder Diensten Dritter.
      </p>

      <h2>9. Internationale Datenübertragungen</h2>
      <p>
        Ihre Daten können an Computer übertragen und dort gespeichert werden, die sich außerhalb Ihres Landes oder anderer staatlicher Gerichtsbarkeiten befinden, in denen die Datenschutzgesetze möglicherweise abweichen. Durch die Nutzung der Plattform stimmen Sie der Übertragung Ihrer Daten in solche Länder zu.
      </p>

      <h2>10. Änderungen dieser Datenschutzerklärung</h2>
      <p>
        Wir können unsere Datenschutzerklärung von Zeit zu Zeit aktualisieren. Wir werden Sie über wesentliche Änderungen informieren, indem wir die neue Datenschutzerklärung auf unserer Plattform veröffentlichen. Ihre fortgesetzte Nutzung der Plattform nach Änderungen bedeutet, dass Sie die neue Datenschutzerklärung akzeptieren.
      </p>

      <h2>11. Kontakt</h2>
      <p>
        Wenn Sie Fragen oder Bedenken bezüglich dieser Datenschutzerklärung haben, kontaktieren Sie uns bitte unter [Kontaktinformationen].
      </p>
    </div>
  );
}

export default PrivacyPolicyDE_DE;
