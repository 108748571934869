import React from 'react';
import './TermsAndConditions.css'; // Import your CSS file for styling

function TermsAndConditionsEN_US() {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to WiseShare. These Terms and Conditions ("Terms") govern your use of our platform. By accessing or using our platform, you agree to comply with these Terms. If you do not agree to these Terms, you must not use our platform.
      </p>

      <h2>2. Definitions</h2>
      <ul>
        <li><strong>"Platform"</strong> refers to WiseShare.</li>
        <li><strong>"User"</strong> refers to any individual or entity who registers for an account or uses the platform.</li>
        <li><strong>"Owner"</strong> refers to users who list items for rent.</li>
        <li><strong>"Renter"</strong> refers to users who rent items listed on the platform.</li>
        <li><strong>"Third-Party Service Providers"</strong> refers to external entities that provide services related to payment processing, identity verification, or other functions.</li>
      </ul>

      <h2>3. Platform Services</h2>
      <p>
        The platform provides a venue for Owners and Renters to connect for the purpose of renting items. The platform itself does not own, manage, or operate any of the items listed and is not involved in any transaction or contract between Owners and Renters beyond facilitating the connection and providing the technical infrastructure.
      </p>

      <h2>4. User Responsibilities</h2>
      <ul>
        <li><strong>Accurate Information:</strong> Users must provide accurate and complete information when creating accounts and listing items.</li>
        <li><strong>Compliance with Laws:</strong> Users agree to comply with all applicable laws, regulations, and ordinances related to their use of the platform and the rental of items.</li>
        <li><strong>Item Condition and Safety:</strong> Owners are responsible for ensuring that items are safe, functional, and as described in the listing. Renters are responsible for using items in accordance with the instructions and returning them in the same condition.</li>
      </ul>

      <h2>5. Limitation of Platform Responsibility</h2>
      <ul>
        <li><strong>No Warranty:</strong> The platform does not warrant the quality, safety, legality, or suitability of any items listed on the platform. All items are provided "as is" without any warranties of any kind, either express or implied.</li>
        <li><strong>No Endorsement:</strong> The platform does not endorse any items, users, or listings. The inclusion of items on the platform does not imply any approval or recommendation by the platform.</li>
        <li><strong>No Liability for Transactions:</strong> The platform is not a party to any rental transaction and is not liable for any disputes, claims, losses, or damages that arise out of or in connection with such transactions. All transactions are solely between the Owner and the Renter.</li>
        <li><strong>No Responsibility for Item Functionality:</strong> The platform is not responsible for the functionality, safety, or legality of the items rented through the platform. Any issues with the items must be resolved directly between the Owner and the Renter.</li>
        <li><strong>No Liability for Loss or Damage:</strong> The platform is not liable for any loss, theft, or damage to items during the rental period, nor for any damage to personal property or injury to persons arising from the use of rented items.</li>
        <li><strong>No Responsibility for Non-Payment:</strong> The platform is not responsible for ensuring payment between Owners and Renters. Any payment disputes must be resolved directly between the parties involved.</li>
        <li><strong>No Liability for Third-Party Services:</strong> The platform may use third-party service providers for payment processing, identity verification, or other services. The platform is not responsible for the actions or omissions of these third-party service providers.</li>
      </ul>

      <h2>6. Indemnification</h2>
      <p>
        Users agree to indemnify, defend, and hold harmless the platform, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in any way connected with (i) the user's access to or use of the platform, (ii) any items rented through the platform, (iii) any breach of these Terms, or (iv) any violation of applicable laws or regulations.
      </p>

      <h2>7. Dispute Resolution</h2>
      <ul>
        <li><strong>Mediation and Arbitration:</strong> Any disputes arising from or relating to the use of the platform shall be resolved through mediation or arbitration, as mutually agreed upon by the parties involved, before resorting to litigation.</li>
        <li><strong>Governing Law:</strong> These Terms are governed by the laws of [Your Country/State]. Any legal proceedings must be brought in the courts of [Your Country/State].</li>
      </ul>

      <h2>8. Termination and Account Suspension</h2>
      <p>
        The platform reserves the right to suspend or terminate user accounts at its discretion, without notice, particularly in cases of suspected fraud, misuse, or violation of these Terms. The platform may also remove or modify any listings that violate these Terms or that are deemed inappropriate.
      </p>

      <h2>9. Modifications to Terms</h2>
      <p>
        The platform reserves the right to modify these Terms at any time. Users will be notified of significant changes, and continued use of the platform constitutes acceptance of the revised Terms.
      </p>

      <h2>10. Entire Agreement</h2>
      <p>
        These Terms constitute the entire agreement between the user and the platform regarding the use of the platform and supersede any prior agreements or understandings, whether written or oral, relating to such use.
      </p>
    </div>
  );
}

export default TermsAndConditionsEN_US;
