import React from 'react';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyEnUs from './PrivacyPolicyEnUs';
import PrivacyPolicyDeDe from './PrivacyPolicyDeDe';
import PrivacyPolicyItIt from './PrivacyPolicyItIt';

function PrivacyPolicyLoader() {
  const { i18n } = useTranslation();
  const language = i18n.language; // e.g., 'en-US', 'de-DE', 'it-IT'

  // Map language codes if necessary
  const languageMap = {
    'en': 'en-US',
    'de': 'de-DE',
    'it': 'it-IT',
  };

  const mappedLanguage = languageMap[language] || 'en-US';

  switch (mappedLanguage) {
    case 'de-DE':
      return <PrivacyPolicyDeDe />;
    case 'it-IT':
      return <PrivacyPolicyItIt />;
    default:
      return <PrivacyPolicyEnUs />; // Default to US English
  }
}

export default PrivacyPolicyLoader;
