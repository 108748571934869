// src/contexts/GoogleMapsContext.js
import React, { createContext, useState } from 'react';
import { LoadScript } from '@react-google-maps/api';

const GoogleMapsContext = createContext();

const libraries = ['places'];

export const GoogleMapsProvider = ({ children }) => {
  const [apiKey] = useState(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

  return (
    <GoogleMapsContext.Provider value={{ apiKey }}>
      <LoadScript googleMapsApiKey={apiKey} libraries={libraries} async defer>
        {children}
      </LoadScript>
    </GoogleMapsContext.Provider>
  );
};

export default GoogleMapsContext;
