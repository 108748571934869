import React from 'react';
import './TermsAndConditions.css'; // You can create and import a CSS file to style the content

function TermsAndConditionsIT_IT() {
  return (
    <div className="terms-and-conditions">
      <h1>Termini e Condizioni</h1>

      <h2>1. Introduzione</h2>
      <p>
        Benvenuto su WiseShare. Questi Termini e Condizioni
        ("Termini") regolano l'uso della nostra piattaforma. Accedendo o
        utilizzando la nostra piattaforma, accetti di rispettare questi Termini.
        Se non accetti questi Termini, non devi utilizzare la nostra piattaforma.
      </p>

      <h2>2. Definizioni</h2>
      <p>
        <strong>"Piattaforma"</strong> si riferisce a WiseShare.<br />
        <strong>"Utente"</strong> si riferisce a qualsiasi persona fisica o giuridica
        che registri un account o utilizzi la piattaforma.<br />
        <strong>"Proprietario"</strong> si riferisce agli utenti che pubblicano oggetti per il noleggio.<br />
        <strong>"Noleggiatore"</strong> si riferisce agli utenti che noleggiano oggetti elencati sulla piattaforma.<br />
        <strong>"Fornitori di Servizi Terzi"</strong> si riferisce a entità esterne che forniscono servizi relativi
        all'elaborazione dei pagamenti, verifica dell'identità o altre funzioni.
      </p>

      <h2>3. Servizi della Piattaforma</h2>
      <p>
        La piattaforma fornisce un luogo di incontro per Proprietari e Noleggiatori
        per connettersi e noleggiare oggetti. La piattaforma stessa non possiede,
        gestisce o controlla gli oggetti elencati e non è coinvolta in nessuna
        transazione o contratto tra Proprietari e Noleggiatori, oltre a facilitare
        la connessione e fornire l'infrastruttura tecnica.
      </p>

      <h2>4. Responsabilità degli Utenti</h2>
      <p>
        <strong>Informazioni Accurate:</strong> Gli utenti devono fornire informazioni
        accurate e complete durante la creazione degli account e la pubblicazione degli oggetti.<br />
        <strong>Rispetto delle Leggi:</strong> Gli utenti si impegnano a rispettare
        tutte le leggi, regolamenti e ordinanze applicabili relative all'uso della
        piattaforma e al noleggio degli oggetti.<br />
        <strong>Condizione e Sicurezza degli Oggetti:</strong> I Proprietari sono
        responsabili di garantire che gli oggetti siano sicuri, funzionanti e come
        descritti nell'inserzione. I Noleggiatori sono responsabili dell'uso degli
        oggetti secondo le istruzioni e della loro restituzione nello stesso stato
        in cui li hanno ricevuti.
      </p>

      <h2>5. Limitazione di Responsabilità della Piattaforma</h2>
      <p>
        <strong>Nessuna Garanzia:</strong> La piattaforma non garantisce la qualità,
        sicurezza, legalità o idoneità degli oggetti elencati sulla piattaforma.
        Tutti gli oggetti sono forniti "come sono" senza alcuna garanzia, espressa
        o implicita.<br />
        <strong>Nessuna Approvazione:</strong> La piattaforma non approva nessun
        oggetto, utente o inserzione. L'inclusione di oggetti sulla piattaforma
        non implica alcuna approvazione o raccomandazione da parte della piattaforma.<br />
        <strong>Nessuna Responsabilità per le Transazioni:</strong> La piattaforma
        non è parte di nessuna transazione di noleggio e non è responsabile per
        eventuali controversie, reclami, perdite o danni derivanti o in relazione
        a tali transazioni. Tutte le transazioni sono esclusivamente tra il
        Proprietario e il Noleggiatore.<br />
        <strong>Nessuna Responsabilità per la Funzionalità degli Oggetti:</strong>
        La piattaforma non è responsabile per la funzionalità, sicurezza o legalità
        degli oggetti noleggiati tramite la piattaforma. Eventuali problemi con gli
        oggetti devono essere risolti direttamente tra il Proprietario e il Noleggiatore.<br />
        <strong>Nessuna Responsabilità per Perdite o Danni:</strong> La piattaforma
        non è responsabile per perdite, furti o danni agli oggetti durante il
        periodo di noleggio, né per danni a proprietà personali o lesioni a persone
        derivanti dall'uso degli oggetti noleggiati.<br />
        <strong>Nessuna Responsabilità per il Mancato Pagamento:</strong> La
        piattaforma non è responsabile per garantire il pagamento tra Proprietari
        e Noleggiatori. Eventuali controversie sui pagamenti devono essere risolte
        direttamente tra le parti coinvolte.<br />
        <strong>Nessuna Responsabilità per i Servizi di Terzi:</strong> La
        piattaforma può utilizzare fornitori di servizi terzi per l'elaborazione
        dei pagamenti, la verifica dell'identità o altri servizi. La piattaforma
        non è responsabile per le azioni o omissioni di questi fornitori di servizi terzi.
      </p>

      <h2>6. Indennizzo</h2>
      <p>
        Gli utenti si impegnano a indennizzare, difendere e manlevare la piattaforma,
        i suoi affiliati, funzionari, direttori, dipendenti e agenti da qualsiasi
        reclamo, responsabilità, danno, perdita o spesa, comprese le spese legali
        ragionevoli, derivanti da o in qualche modo collegati a (i) l'accesso o
        l'uso della piattaforma da parte dell'utente, (ii) qualsiasi oggetto
        noleggiato tramite la piattaforma, (iii) qualsiasi violazione di questi
        Termini, o (iv) qualsiasi violazione delle leggi o dei regolamenti applicabili.
      </p>

      <h2>7. Risoluzione delle Controversie</h2>
      <p>
        <strong>Mediazione e Arbitrato:</strong> Qualsiasi controversia derivante
        dall'uso della piattaforma o correlata alla stessa sarà risolta attraverso
        la mediazione o l'arbitrato, come concordato tra le parti coinvolte,
        prima di ricorrere al contenzioso.<br />
        <strong>Legge Applicabile:</strong> Questi Termini sono regolati dalla
        legge italiana. Qualsiasi procedimento legale deve essere portato davanti
        ai tribunali italiani.
      </p>

      <h2>8. Chiusura e Sospensione dell'Account</h2>
      <p>
        La piattaforma si riserva il diritto di sospendere o chiudere gli account
        degli utenti a sua discrezione, senza preavviso, in particolare in caso di
        sospetta frode, uso improprio o violazione di questi Termini. La piattaforma
        può anche rimuovere o modificare qualsiasi inserzione che violi questi
        Termini o che sia considerata inappropriata.
      </p>

      <h2>9. Modifiche ai Termini</h2>
      <p>
        La piattaforma si riserva il diritto di modificare questi Termini in
        qualsiasi momento. Gli utenti saranno informati di eventuali modifiche
        significative, e l'uso continuato della piattaforma costituisce accettazione
        dei Termini modificati.
      </p>

      <h2>10. Intero Accordo</h2>
      <p>
        Questi Termini costituiscono l'intero accordo tra l'utente e la piattaforma
        in relazione all'uso della piattaforma e sostituiscono qualsiasi accordo o
        intesa precedente, sia scritta che orale, relativa a tale uso.
      </p>
    </div>
  );
}

export default TermsAndConditionsIT_IT;
