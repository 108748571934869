import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import './Homepage.css'; // Import custom CSS for styling

function Homepage() {
  const { t } = useTranslation(); // Destructure t from useTranslation

  return (
    <div className="homepage">
      <header className="hero-section">
        <h1>{t('welcomeTitle')}</h1>
        <p>{t('welcomeSubtitle')}</p>
        <div className="button-container">
          <Link to="/items" className="cta-button">{t('browseItems')}</Link>
          <Link to="/login" className="cta-button login-button">{t('login')}</Link>
        </div>
      </header>
      
      <section className="features">
        <div className="feature">
          <h2>{t('findWhatYouNeedTitle')}</h2>
          <p>{t('findWhatYouNeedDescription')}</p>
        </div>
        <div className="feature">
          <h2>{t('easyBookingTitle')}</h2>
          <p>{t('easyBookingDescription')}</p>
        </div>
        <div className="feature">
          <h2>{t('manageYourItemsTitle')}</h2>
          <p>{t('manageYourItemsDescription')}</p>
        </div>
      </section>
    </div>
  );
}

export default Homepage;
